<template>
    <div class="w-full h-screen flex flex-col">
      <header class="site-header bg-gray-50 shadow-md p-6 min-h-60 flex-none">
        <h2><img src="https://assets.codepen.io/3696383/p1-logo-dark.svg"/><span class="sr-only">ParticleOne</span></h2>
      </header>

      <div class="page flex-grow flex items-center">
        <div class="overflow-hidden max-w-md mx-auto">
          <div class="px-4 py-5">
            <h4 class="font-bold text-2xl grey-700 mb-4">Sign in with</h4>
            <a :href="loginUrl" class="min-w-full inline-flex items-center px-3 py-3 border border-gray-300 shadow-sm text-base font-medium rounded-md font-bold text-gray-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">

              <svg class="mr-3" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                <g id="Group_2564" data-name="Group 2564" transform="translate(16622 -4975)">
                  <g id="Rectangle_816" data-name="Rectangle 816" transform="translate(-16622 4975)" fill="#f25022" stroke="#fff" stroke-width="2">
                    <rect width="18.947" height="18.947" stroke="none"/>
                    <rect x="1" y="1" width="16.947" height="16.947" fill="none"/>
                  </g>
                  <g id="Rectangle_819" data-name="Rectangle 819" transform="translate(-16622 4992.053)" fill="#00a4ef" stroke="#fff" stroke-width="2">
                    <rect width="18.947" height="18.947" stroke="none"/>
                    <rect x="1" y="1" width="16.947" height="16.947" fill="none"/>
                  </g>
                  <g id="Rectangle_817" data-name="Rectangle 817" transform="translate(-16604.947 4975)" fill="#7fba00" stroke="#fff" stroke-width="2">
                    <rect width="18.947" height="18.947" stroke="none"/>
                    <rect x="1" y="1" width="16.947" height="16.947" fill="none"/>
                  </g>
                  <g id="Rectangle_818" data-name="Rectangle 818" transform="translate(-16604.947 4992.053)" fill="#ffb900" stroke="#fff" stroke-width="2">
                    <rect width="18.947" height="18.947" stroke="none"/>
                    <rect x="1" y="1" width="16.947" height="16.947" fill="none"/>
                  </g>
                </g>
              </svg>
              Active Directory
            </a>
            <p class="mt-3"><strong>Trouble signing in?</strong> <a href="mailto:help@particleone.com" class="text-pink-600 underline hover:no-underline">Contact us</a> for questions or support.</p>
          </div>
        </div>
      </div>

      <footer class="site-footer flex-none p-6 min-h-60">©{{ new Date().getFullYear() }} ParticleOne Inc. All rights reserved.</footer>
    </div>
</template>

<script>
export default {
  name: 'Login',
  pageTitle: 'Login',
  computed: {
    loginUrl() {
      return `${process.env.VUE_APP_TOKEN_LOGIN_URL}?post_login_redirect_uri=https%3A%2F%2F${window.location.host}${encodeURIComponent('/#/login-complete/')}`;
    }
  },
  mounted() {
    localStorage.removeItem('redirect_from');
    if (this.$router.currentRoute.value.query.redirect_from) {
      localStorage.setItem('redirect_from', this.$router.currentRoute.value.query.redirect_from);
    }
  }
};
</script>

<style>
</style>